.wrapper {
  height: 100vh;
  margin: 0 auto;
}

.aside {
  :global {
    .ant-menu-item:first-child {
      margin-top: 0;
    }

    .ant-menu-root {
      padding: 24px 2px 24px 0;
    }
  }
}

.content {
  display: flex;
  padding: 24px 26px 0;
  overflow: hidden auto;
  flex-direction: column;
}

.loginContent {
  padding: 0;
}

.footer {
  display: flex;
  background: var(--gray-10);
  color: var(--gray-1);
  justify-content: center;
  padding: 0;
}
