.wrapper {
  display: flex;
  height: 100%;
}

.logoWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  margin-left: -16px;
}

.verticalLine {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: 1px;
  background: var(--gray-8);
}

.authWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  width: 100%;
}

.userWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

h5.name {
  color: var(--gray-1);
  margin: 0;
}
